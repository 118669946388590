import { SelectMenu } from '@local/web-design-system';
import { Grid } from '@mui/material';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Subscription } from 'state-domains/domain';
import { PathConstants, withRouter } from 'src/routes';
import { withStyles } from 'src/styles/utils';
import { EVENT_TYPES, TRACKING_COMPONENTS, trackUserAction } from 'src/utilities';
import React from 'react';

import { connectToState } from './AccountSelect.connect';
import { styles } from './AccountSelect.styles';
import { AccountSelectProps } from './AccountSelect.types';

export class AccountSelectBase extends React.Component<AccountSelectProps, { currentId: string }> {
    constructor(props: any) {
        super(props);
        this.state = { currentId: '' };
    }

    componentDidMount() {
        this.setState({ currentId: this.props.selectedSubscriptionId || '' });
    }

    setSubscription = (value: string | number) => {
        const newValue = value.toString();
        if (this.state.currentId !== newValue) {
            this.setState({ currentId: newValue });
            this.props.switchSubscription(newValue);
            trackUserAction(TRACKING_COMPONENTS.HEADER, EVENT_TYPES.CHANGE, 'org', {
                newSubscriptionId: newValue,
            });
            this.props.navigate(PathConstants.HOME.ROOT);
        }
    };

    render() {
        const { classes, subscriptions: accounts } = this.props;
        const enabledAccounts = accounts.filter((account) => account?.eap?.accessNewApp);
        enabledAccounts.sort((a, b) => {
            if (a.accountName < b.accountName) return -1;
            if (a.accountName > b.accountName) return 1;
            return 0;
        });

        return (
            <Grid className={classes.buttonDefault} automation-id="MXDeposit-account-select">
                {enabledAccounts.length > 1 ? (
                    <SelectMenu
                        options={enabledAccounts.map((sub: Subscription) => {
                            let label = sub.accountName;
                            if (sub.apiRegion !== 'us') {
                                label = `${sub.accountName} (${sub.apiRegion?.toUpperCase() ?? ''})`;
                            }
                            return {
                                key: sub.subscriptionId,
                                label,
                            };
                        })}
                        applyTraceMessage="account"
                        onSelect={this.setSubscription}
                        selected={enabledAccounts.length > 1 ? this.state.currentId : ''}
                        popoverClasses={{ root: classes.popoverClasses }}
                        classes={{
                            menuLabel: classes.mySeequentMenuLabel,
                            menuItem: classes.mySeequentMenuItem,
                            paper: classes.mySeequentPaper,
                        }}
                    />
                ) : null}
            </Grid>
        );
    }
}

export const AccountSelect = compose(
    connectToState,
    withStyles(styles),
    withRouter,
    injectIntl,
)(AccountSelectBase) as React.FC<any>;
